









































import Vue from "vue";
import Swatches from "vue-swatches";
import { getGlobalConfiguration } from "@web/global-config";
import { textColor } from "@backend/theming/color_contrast";

export default Vue.extend({
    name: "ColorPicker",
    components: {
        Swatches
    },
    props: {
        label: {
            type: String,
            required: true
        },
        type: {
            type: String
        },

        color: {
            type: String,
            required: true
        }
    },
    computed: {
        colorSwatches() {
            if (this.type === "textColor") {
                return ["#000000", "#333333", "#DDDDDD", "#FFFFFF"];
            } else {
                return [
                    "#00CC76",
                    "#5353D9",
                    "#BCE4FA",
                    "#24A1DC",
                    "#20398E",
                    "#E63370",
                    "#EB2226",
                    "#FF3E17",
                    "#FBB80A",
                    "#777777",
                    "#444444",
                    "#FFFFFF",
                ];
            }
        }
    },
    methods: {

        rgbToHex(arr: string[]) {
            return "#" + arr.map((x: any) => {
                const hex = x.toString(16);
                return hex.length === 1 ? "0" + hex : hex;
            }).join("");
        },

        colorPicked(newValue: string) {
            if (!newValue.includes("#")) {
                newValue = "#" + newValue;
            }
            this.$emit("input", newValue);
            this.$emit("contrast", textColor(newValue, getGlobalConfiguration().theming_color_contrast_ratio_min));
        }
    }
});
