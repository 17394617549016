/*
 * Color contrast and luminance calculation as described in https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure
 * Implementation inspired by https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors
 */

export interface Rgb {
    r: number,
    g: number,
    b: number,
}

export const WHITE_RGB = { r: 255, g: 255, b: 255 };
export const BLACK_RGB = { r: 0, g: 0, b: 0 };

export function hexToRgb(hex: string): Rgb {
    if (hex.length < 4) {
        return BLACK_RGB;
    }
    if (hex.length < 7) {
        hex = hex.slice(0, 2) + hex[1] + hex[2] + hex[2] + hex[3] + hex[3];
    }
    const r = parseInt(hex.substr(1, 2), 16);
    const g = parseInt(hex.substr(3, 2), 16);
    const b = parseInt(hex.substr(5, 2), 16);
    return { r, g, b };
}

export function luminance(rgb: Rgb): number {
    const a = [rgb.r, rgb.g, rgb.b].map(function(v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function contrast(rgb1: Rgb, rgb2: Rgb): number {
    const lum1 = luminance(rgb1);
    const lum2 = luminance(rgb2);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
}

export function textColor(hex: string, minTestContrastRatio: number) {
    // Use same logic as in ThemingState.dart
    const rgb = hexToRgb(hex);
    const contrastWhite = contrast(rgb, WHITE_RGB);
    return contrastWhite >= minTestContrastRatio ? "#ffffff" : "#000000";
}
