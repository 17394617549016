














































































































































































































































import Vue from "vue";
import { Intranet } from "@backend/intranet/types";
import { THEMING_MODULE_NAME } from "@web/store/theming/theming";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    INTRANET,
    LOGO_URL
} from "@web/store/intranet/getters";
import { COLORS } from "@web/store/theming/getters";
import { IntranetColors } from "@backend/theming/types";

    declare interface ComponentData {
        selectedColors: IntranetColors | undefined;
    }

export default Vue.extend<{}, {}, {}, {}>({
    name: "Preview",
    props: {
        type: {
            type: String,
            default: "desktop"
        }
    },
    data(): ComponentData {
        return {
            selectedColors: undefined
        };
    },
    computed: {
        intranet(): Intranet {
            return this.$store.getters[INTRANET_MODULE_NAME + INTRANET];
        },
        themeColors() {
            return this.$store.getters[THEMING_MODULE_NAME + COLORS];
        },
        logoUrl() {
            return this.$store.getters[INTRANET_MODULE_NAME + LOGO_URL];
        }
    }
});
