<template>
    <div
        v-if="this.intranet"
        class="pbottom-large"
    >
        <h2>{{ $t("theming") }}</h2>
        <p>{{ $t('theming_description') }}</p>
        <SuggestedColorBox
            v-if="!this.logoIsDefaultLinchpinLogo && showAutoTheming"
            :suggested-colors="suggestedColors"
            @applyColors="applySuggestedColors"
        />
        <ThemeColors :intranet="this.intranet" />

        <StickyFooter>
            <div class="container">
                <div class="button-group">
                    <app-button
                        type="primary"
                        :busy="busy"
                        @click="save"
                    >
                        {{ $t("save") }}
                    </app-button>
                    <a
                        class="button link"
                        @click="resetColors"
                    >
                        {{ $t("reset") }}
                    </a>
                </div>
            </div>
        </StickyFooter>
    </div>
</template>

<script>
import Vue from "vue";
import ThemeColors from "@web/views/intranet/admin/theming/Colors.vue";
import { INTRANET } from "@web/store/intranet/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { THEMING_MODULE_NAME } from "@web/store/theming/theming";
import { RESET_COLORS } from "@web/store/theming/actions";
import { COLORS } from "@web/store/theming/getters";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import {
    intranetService,
    themeService
} from "@web/services";
import { SET_COLORS } from "@web/store/intranet/mutations";

import StickyFooter from "@web/components/StickyFooter.vue";

import SuggestedColorBox from "@web/views/intranet/admin/theming/SuggestedColorBox";
import { mapMutations } from "vuex";

const DEFAULT_LINCHPIN_LOGO = "default/logo/logo.png";

export default Vue.extend({
    name: "Theming",
    components: {
        SuggestedColorBox,
        ThemeColors,
        StickyFooter,
    },
    data() {
        return {
            busy: false,
            suggestedColors: {},

        };
    },
    computed: {
        intranet() {
            return this.$store.getters[INTRANET_MODULE_NAME + INTRANET];
        },
        colors() {
            return this.$store.getters[THEMING_MODULE_NAME + COLORS];
        },
        logoIsDefaultLinchpinLogo() {
            return this.$store.getters[INTRANET_MODULE_NAME + INTRANET].logo === DEFAULT_LINCHPIN_LOGO;
        },
        showAutoTheming() {
            return localStorage.getItem("showAutoTheming") === "true";
        },
    },
    mounted() {
        this.resetColors();
        this.getSuggestedLogoColors();
    },
    methods: {
        ...mapMutations({ setColors: THEMING_MODULE_NAME + "setColors" }),
        resetColors() {
            this.$store.dispatch(THEMING_MODULE_NAME + RESET_COLORS);
        },
        async getSuggestedLogoColors() {
            this.suggestedColors = (await themeService.getSuggestedThemeColors(this.intranet.uid)).data;
        },
        async save() {
            try {
                this.busy = true;
                await themeService.saveThemeSettings(this.intranet.uid, this.colors);
                analytics.log(getGlobalConfiguration().analytics_event_name_theme_updated);
                localStorage.setItem("showAutoTheming", "false");
                this.$store.commit(INTRANET_MODULE_NAME + SET_COLORS, this.colors);
                this.$notify({
                    group: "app",
                    type: "success",
                    text: this.$t("theming_save_success").toString(),
                });
                await intranetService.clearIntranetApiCache();
            } finally {
                this.busy = false;
            }
        },
        applySuggestedColors() {
            this.setColors({ ...this.suggestedColors });
            analytics.log(getGlobalConfiguration().analytics_event_name_theme_applied_suggested_colors);
        },
    },
});
</script>

<style scoped>
</style>
