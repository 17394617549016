

























































import Vue from "vue";
import ColorPicker from "@web/views/intranet/admin/theming/ColorPicker.vue";
import { Intranet } from "@backend/intranet/types";
import { THEMING_MODULE_NAME } from "@web/store/theming/theming";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";
import { COLORS } from "@web/store/theming/getters";
import { UPDATE_COLOR } from "@web/store/theming/mutations";

export default Vue.extend({
    name: "ColorGroup",
    components: {
        ColorPicker
    },
    props: {
        title: {
            type: String,
            required: true
        },
        description: {
            type: String
        },
        backgroundColor: {
            type: String,
            required: true
        },
        textColor: {
            type: String,
            required: true
        },
        additionalColors: {
            default: function(): object {
                return {};
            }
        }
    },
    computed: {
        intranet(): Intranet {
            return this.$store.getters[INTRANET_MODULE_NAME + INTRANET];
        },
        themeColors() {
            return this.$store.getters[THEMING_MODULE_NAME + COLORS];
        },
        additionalColorsKeys() {
            return Object.keys(this.additionalColors as object);
        }
    },
    methods: {
        updateColorsInStore(color: string, colorKey: string) {
            this.$store.commit(
                THEMING_MODULE_NAME + UPDATE_COLOR,
                { colorKey, color }
            );
        },
        updateTextColor(contrastColor: string) {
            if (this.themeColors) {
                this.$store.commit(
                    THEMING_MODULE_NAME + UPDATE_COLOR,
                    { colorKey: this.textColor, color: contrastColor }
                );
            }
        }
    }
});
