



















































































import Vue from "vue";
import ColorGroup from "@web/views/intranet/admin/theming/ColorGroup.vue";
import ThemingPreview from "@web/views/intranet/admin/theming/ThemingPreview.vue";

export default Vue.extend({
    name: "ThemeColors",
    components: {
        ColorGroup,
        ThemingPreview
    },
    data() {
        return {
            previewMobile: false
        };
    }
});
