<template>
    <transition name="fade">
        <div
            v-if="suggestedColors.primaryColor && visible"
            key="color-box"
            class="card depth-2 padding-small mbottom-medium"
        >
            <h3>{{ $t("autotheming_headline") }}</h3>
            <p>{{ $t("autotheming_question") }}</p>
            <div class="swatches pbottom-medium">
                <div
                    class="swatch-tile depth-2"
                    :style="
                        `background: ${suggestedColors.primaryColor}; color: ${suggestedColors.primaryTextColor}`
                    "
                >
                    {{ $t("primary") }}
                </div>
                <div
                    class="swatch-tile depth-2"
                    :style="
                        `background: ${suggestedColors.secondaryColor}; color: ${suggestedColors.secondaryTextColor}`
                    "
                >
                    {{ $t("secondary") }}
                </div>
                <div
                    class="swatch-tile depth-2"
                    :style="
                        `background: ${suggestedColors.errorColor}; color: ${suggestedColors.errorTextColor}`
                    "
                >
                    {{ $t("warning") }}
                </div>
                <div
                    class="swatch-tile depth-2"
                    :style="
                        `background: ${suggestedColors.infoColor}; color: ${suggestedColors.infoTextColor}`
                    "
                >
                    {{ $t("info") }}
                </div>
            </div>

            <div class="button-group">
                <app-button
                    type="primary"
                    @click="handleApply"
                >
                    Apply
                </app-button>
                <app-button
                    type="link no-padding"
                    @click="handleHide"
                >
                    No, thank you
                </app-button>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "SuggestedColorBox",
    props: {
        suggestedColors: {
            type: Object
        }
    },
    data() {
        return {
            visible: true
        };
    },
    methods: {
        handleApply() {
            this.$notify({
                group: "app",
                type: "succes",
                text: this.$t("autotheming_applied")
            });
            this.$emit("applyColors");
            this.visible = false;
        },
        handleHide() {
            this.visible = false;
            localStorage.setItem("showAutoTheming", "false");
        }
    }
};
</script>

<style lang="scss" scoped>
    .swatches {
        position: relative;
        max-width: 600px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        user-select: none;

        .swatch-tile {
            width: 9rem;
            height: 5rem;
            padding: 0.75rem;
            margin-right: 0.5rem;
            border-radius: var(--border-radius);
            font-weight: 600;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            line-height: 1.2;
        }
    }
</style>
